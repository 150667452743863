'use client';

import React, { ReactElement, useEffect } from 'react';
import {
    FiAlertCircle as AlertCircleIcon,
    FiShoppingCart as ShoppingCartIcon,
    FiChevronDown as ChevronDownIcon,
} from 'react-icons/fi';
import { useTranslations } from 'next-intl';
import { toastApi } from '@/lib/context/toasts';
import { useMiniCart } from './hooks/useMiniCart';
import Price from '@/components/Price';
import Button from '@/components/Button';
import StockStatusMessage from '@/components/StockStatusMessage';
import CollapsibleSSR from '@/components/CollapsibleSSR';
import ShippingSummary from '@/components/PriceSummary/shippingSummary';
import TaxSummary from '@/components/PriceSummary/taxSummary';
import ProductList from './modules/ProductList';
import MiniCartSkeleton from './modules/Skeleton/miniCart';
import { ToastEnum } from '@/lib/context/toasts/toastApi';

import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './styles/miniCart.module.scss';

const MINUS_SYMBOL = '-';

interface IMiniCartProps {
    classes?: {
        [className: string]: string;
    };
    itemCount: number;
    setIsOpen: (value: boolean) => void;
}

const MiniCart = ({ classes: propsClasses, itemCount, setIsOpen }: IMiniCartProps): ReactElement => {
    const {
        closeMiniCart,
        configurableThumbnailSource,
        confirmRemoveItemId,
        errorMessage,
        errors,
        handleCancelRemoveItem,
        handleConfirmRemoveItem,
        handleEditCart,
        handleProceedToCheckout,
        handleRemoveItem,
        isPriceIncludesTax,
        isRemovingProduct,
        isUpdatingProduct,
        prices,
        productList,
        setIsUpdatingQuantity,
        shipping,
        shouldShowSkeleton,
        storeCredit,
        totalSavingWithStoreCredit,
    } = useMiniCart({
        setIsOpen,
    });

    const tMiniCart = useTranslations('miniCart');
    const tPriceSummary = useTranslations('priceSummary');

    const classes = mergeClasses(defaultClasses, propsClasses);
    const orderSummaryClassName =
        isRemovingProduct || isUpdatingProduct ? classes.orderSummaryLoading : classes.orderSummary;

    const isCartEmpty = !itemCount;
    const isButtonDisabled = isRemovingProduct || isUpdatingProduct || isCartEmpty;

    useEffect(() => {
        if (errorMessage) {
            toastApi.add({
                dismissible: true,
                icon: <AlertCircleIcon />,
                message: errorMessage,
                variant: ToastEnum.error,
            });
        }
    }, [errorMessage]);

    if (shouldShowSkeleton && !isCartEmpty) {
        return <MiniCartSkeleton itemCount={itemCount} />;
    }

    if (isCartEmpty) {
        return (
            <div className={classes.emptyCart}>
                <span className={classes.iconWrapper}>
                    <ShoppingCartIcon className={classes.emptyCartIcon} />
                </span>
                <div className={classes.emptyMessage}>{tMiniCart('emptyMessage')}</div>
            </div>
        );
    }

    console.log('shipping', shipping);
    const orderSummary =
        totalSavingWithStoreCredit || prices || storeCredit ? (
            <div className={orderSummaryClassName} data-mt-type="mini-cart-order-summary">
                <div className={classes.stockStatusMessageContainer}>
                    <StockStatusMessage cartItems={productList} />
                </div>
                <CollapsibleSSR
                    className={classes.orderTotalBox}
                    openedClassName={classes.orderTotalBoxOpen}
                    trigger={
                        <button className={classes.orderTotalButton} title={tPriceSummary('total')}>
                            <span className={classes.orderSummaryTotalRow}>
                                <span>
                                    {tPriceSummary('total')}
                                    {isPriceIncludesTax && (
                                        <span className={classes.totalsVatText}>
                                            {''} {tPriceSummary('inclVatText')}
                                        </span>
                                    )}
                                </span>
                                <ChevronDownIcon className={classes.icon} />
                            </span>
                            {prices?.total && (
                                <span className={`${classes.orderSummaryTotalRow} ${classes.price}`}>
                                    <Price value={prices?.total?.value} currencyCode={prices?.total?.currency} />
                                </span>
                            )}
                        </button>
                    }
                    transitionTime={160}
                >
                    <div className={classes.orderSummaryDetails}>
                        {prices?.subtotal && (
                            <div className={classes.orderSummaryRow}>
                                {tPriceSummary('lineItemLabel')}
                                <span className={classes.price}>
                                    <Price currencyCode={prices?.subtotal?.currency} value={prices?.subtotal?.value} />
                                </span>
                            </div>
                        )}
                        {prices?.totalSavings && (
                            <div className={classes.orderSummaryRow}>
                                {tPriceSummary('discountLabel')}
                                <span className={classes.price}>
                                    {MINUS_SYMBOL}
                                    <Price
                                        currencyCode={prices?.totalSavings?.currency}
                                        value={prices?.totalSavings?.value}
                                    />
                                </span>
                            </div>
                        )}
                        {storeCredit && (
                            <div className={classes.orderSummaryRow}>
                                {tPriceSummary('storeCreditLabel')}
                                <span className={classes.price}>
                                    {MINUS_SYMBOL}
                                    <Price currencyCode={storeCredit.currency} value={storeCredit.value} />
                                </span>
                            </div>
                        )}
                        {shipping && (
                            <div className={classes.orderSummaryRow}>
                                <ShippingSummary data={shipping} isCheckout isProActive={false} />
                            </div>
                        )}
                        {!isPriceIncludesTax && (
                            <div className={classes.orderSummaryRow}>
                                <TaxSummary data={prices?.taxes} isCheckout />
                            </div>
                        )}
                    </div>
                </CollapsibleSSR>
                {(!shipping?.[0]?.selected_shipping_method?.amount || !shipping?.length) &&
                    (isPriceIncludesTax
                        ? tMiniCart('shippingCalculationsDisclaimer')
                        : !shipping?.length
                          ? tMiniCart('calculationsDisclaimer')
                          : tMiniCart('shippingCalculationsDisclaimer'))}
            </div>
        ) : null;

    return (
        <>
            <div className={classes.body} data-mt-type="mini-cart-body">
                <ProductList
                    items={productList}
                    handleRemoveItem={handleRemoveItem}
                    closeMiniCart={closeMiniCart}
                    configurableThumbnailSource={configurableThumbnailSource}
                    handleConfirmRemoveItem={handleConfirmRemoveItem}
                    handleCancelRemoveItem={handleCancelRemoveItem}
                    confirmRemoveItemId={confirmRemoveItemId}
                    setIsUpdatingQuantity={setIsUpdatingQuantity}
                />
            </div>
            <div className={classes.summary}>
                {orderSummary}
                <Button
                    onClick={handleProceedToCheckout}
                    variant={'primary'}
                    isFullWidth={true}
                    className={classes.checkoutButton}
                    disabled={isButtonDisabled || !!errors?.length}
                >
                    {tMiniCart('checkout')}
                </Button>
                <Button
                    onClick={handleEditCart}
                    variant={'secondary'}
                    isFullWidth={true}
                    className={classes.editCartButton}
                    disabled={isButtonDisabled}
                >
                    {tMiniCart('editCartButton')}
                </Button>
            </div>
        </>
    );
};

MiniCart.displayName = 'MiniCart';

export default MiniCart;
